import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, Stones, StoneStyles } from 'components/icons'
import { Nav } from 'components/nav'
import { TechSection } from 'components/icons/Technologies'
import { AWSIoT, MapBox, OpenDDS, OpenGL, Qt, VR, WebRTC } from '../../components/icons/Technologies'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const AutotechPage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <Analytics />
    <SEO title="AutoTech" pageUrl="/industries/autotech/" description="Pure passion for technology, coupled with endless admiration for the automotive industry are the roots of our focus and dedication to participate actively in the future of mobility." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 pt-6">
        <HeaderLink />
        <div className="pt-12 text-left text-primary-3 text-shadow">
          <h1 className="pt-4 text-4xl lg:text-5xl">AutoTech</h1>
        </div>
      </Header>
      <BackgroundWithGrid image="industry-autotech-hero" bottom={true} gridRow={2} />
      <Deco decoType={2} before inner gridRow={2} className="z-0" />
      <Section className="z-20 h-72" gridRow={2} />
      <Section className="z-20 pt-12 pb-48 grid gap-12 grid-cols-6 lg:grid-cols-12" gridRow={3}>
        <p className="col-span-6 text-xl lg:text-2xl">Pure passion for technology, coupled with endless admiration for the automotive industry are the roots of our focus and dedication to participate actively in the future of mobility.</p>
        <div className="col-span-5">
          <p>
            Tumba is an integral part of the team responsible for building software for self-driving cars and autonomous robo-taxi fleets. We have industry understanding, own trial solutions and major strive to help you transform the automotive industry
            worldwide. AutoTech is currently in the state of search and integration of mobile solutions, in which we have more than 20 years of experience and several breakthroughs.
          </p>
        </div>
        <div className="col-span-6">
          <h2 className="col-span-5 text-2xl lg:text-3xl">Key tech & tools</h2>
          <div className="mt-10 grid gap-x-4 gap-y-8 grid-cols-3">
            <TechSection Icon={MapBox} rotate={0} text="MapBox" />
            <TechSection Icon={AWSIoT} rotate={-75} text="AWS AWSIoT" />
            <TechSection Icon={OpenDDS} rotate={90} text="OpenDDS" />
            <TechSection Icon={WebRTC} rotate={0} text="WebRTC" />
            <TechSection Icon={OpenGL} rotate={-75} text="OpenGL" />
            <TechSection Icon={VR} rotate={90} text="VR" />
            <TechSection Icon={Qt} rotate={0} text="Qt" />
          </div>
        </div>
        <div className="col-span-5">
          <h2 className="text-2xl lg:text-3xl">Services</h2>
          <div>
            <div className="pt-10 flex items-baseline text-primary-1">
              <h3 className="text-lg">Team Extensions</h3>
            </div>
            <span className="block mt-5 text-base font-normal tracking-normal">
              Your team needs expertise measured in productivity, not in #devs per hour. We will be an integral part of your team, enhance the performance and satiate the valid business needs.
            </span>
            <div className="pt-10 flex items-baseline text-primary-1">
              <h3 className="text-lg">Proof of Concept</h3>
            </div>
            <span className="block mt-5 text-base font-normal tracking-normal">
              You have a solid business idea. You know the tech behind is crucial and you know you need a partner. We will bring clarity and efficiency. We will make sure you see your idea validated.
            </span>
          </div>
        </div>
      </Section>
      <Deco before after className="bg-primary-1" gridRow={4} />
      <Section className="z-20 pt-12 grid gap-12 grid-cols-3 text-primary-2" gridRow={4}>
        <h2 id="success-stories" className="col-span-3 text-2xl lg:text-3xl">
          Success stories
        </h2>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">I was in the car late last week, and the visualization is looking very, very good. Excellent work by all involved!</p>
          <p className="mt-8">Karl, CEO</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">Good thorough thinking about features, quality of code and robust features built.</p>
          <p className="mt-8">Renaud, Director of Connected Vehicle</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            It's been great working with Tumba. They bring good insight into product discussions. They can always be trusted to implement with minimal guidance. I appreciate their willingness to be flexible in their development process to accommodate changes
            in business.
          </p>
          <p className="mt-8">Ian, Director of Product</p>
        </div>
      </Section>
      <Section className="z-20 pt-40 pb-12 grid gap-12 grid-cols-6 lg:grid-cols-11" gridRow={5}>
        <div className="col-span-6 lg:col-span-11">
          <Stones className="h-20 overflow-visible" stoneClasses="transform origin-center box-fill" styles={StoneStyles[0]} />
          <p className="pt-6 text-2xl lg:text-3xl">Explore</p>
        </div>
        <Link to="/services/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Services</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/expertise/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Expertise</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/team/" className="pt-8 col-span-3 flex items-baseline text-primary-1">
          <span className="text-lg">Team</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
      </Section>
      <BackgroundWithGrid className="relative h-72" image="industry-autotech-hero" bottom={true} gridRow={6}>
        <div className="absolute inset-x-0 bottom-0 h-24" style={{ background: 'linear-gradient(#00edae00, #00edae)' }} />
      </BackgroundWithGrid>
      <Deco decoType={2} after gridRow={5} />
      <Nav className="z-20 text-primary-2" gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default AutotechPage
